import jwtDecode from "jwt-decode";
import axios from "axios";

// Configuración de la URL base desde las variables de entorno
axios.defaults.baseURL = process.env.REACT_APP_BACKEND_URL;
axios.defaults.headers.post['Content-Type'] = 'application/json';

// Interceptar respuestas para manejar errores globalmente
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    let message;

    if (error && error.response) {
      switch (error.response.status) {
        case 401:
          message = 'Invalid credentials';
          break;
        case 403:
          message = 'Access Forbidden';
          // Redirigir a la página de logout
          window.location.href = '/#/auth/logout';
          break;
        case 404:
          message = 'Sorry! The data you are looking for could not be found';
          break;
        default:
          message =
            error.response.data?.message || error.message || 'An error occurred';
      }
    }
    return Promise.reject(message || 'An error occurred');
  }
);

const AUTH_SESSION_KEY = 'digital_product_user';

/**
 * Establece el encabezado de autorización predeterminado
 * @param {*} token
 */
const setAuthorization = (token: string | null) => {
  if (token) {
    axios.defaults.headers.common['Authorization'] = 'JWT ' + token;
  } else {
    delete axios.defaults.headers.common['Authorization'];
  }
};

/**
 * Obtiene el usuario del almacenamiento de sesión
 */
const getUserFromCookie = () => {
  const user = sessionStorage.getItem(AUTH_SESSION_KEY);
  return user ? JSON.parse(user) : null;
};

const isUserAuthenticated = () => {
  const user = getUserFromCookie();
  if (!user) return false;

  const decoded: any = jwtDecode(user.token);
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    console.warn("Access token expired");
    return false;
  }
  return true;
};

const getLoggedInUser = () => {
  return getUserFromCookie();
};

const setLoggedInUser = (user: any) => {
  if (user) {
    sessionStorage.setItem(AUTH_SESSION_KEY, JSON.stringify(user));
  } else {
    sessionStorage.removeItem(AUTH_SESSION_KEY);
  }
};

export { axios, setAuthorization, isUserAuthenticated, getLoggedInUser, setLoggedInUser };